@charset "UTF-8";

@import url("https://fonts.googleapis.com/css2?family=Asap&display=swap");


.reveal {
  position: relative;
  opacity: 0;
}

.reveal.active {
  opacity: 1;
}
.active.fade-bottom {
  animation: fade-bottom 1s ease-in;
}
.active.fade-top {
  animation: fade-top 1s ease-in;
}
.active.fade-left {
  animation: fade-left 1s ease-in;
}
.active.fade-right {
  animation: fade-right 1s ease-in;
}
.active.fade-zoomin {
  animation: fade-zoomin 1s ease-in;
}
.active.slide-InRight {
  animation: slide-InRight 1s ease-in;
}

@keyframes slide-InRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fade-zoomin {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}
@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-top {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}


.outter.hero-video {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  .outter.hero-video {
    height: 325px;
  }
}

.hero-video .video-container {
  height: 550px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 767px) {
  .hero-video .video-container {
    height: 325px;
  }
}
.hero-video video {
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  height: 550px;
  width: 100%;
  top: 0;
  left: 0;
}
@media (max-width: 767px) {
  .hero-video video {
    height: 325px;
  }
}
.hero-video .video-container:after {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.hero-video h1 {
  text-transform: uppercase;
  margin: 0 0 1rem;
  padding: 0;
  line-height: 1;
  color: white;
}
@media (max-width: 767px) {
  .hero-video h1 {
    font-size: 32px;
  }
}
@media (min-width: 768px) {
  .hero-video h1 {
    font-size: 52px;
  }
}
.hero-video .desc {
  color: white;
  font-weight: 400;
  font-size: 18px;
}
.hero-video .callout {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: right;
  flex-direction: column;
  height: 100%;
  text-align: right;
  position: relative;
  z-index: 10;
  width: 70%;
  margin: auto;
}

@media (max-width: 767px) {
  .hero-video .callout {
    width: 90%;
  }
}

/* pop up model*/
.PopModal {
  display: none; 
  position: fixed; 
  z-index: 999;
  padding-top: 50px;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4); 
}

.PopModal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.active.bigEntrance {
  animation: bigEntrance 1s ease-in;
}


@keyframes bigEntrance {
  0% {
    transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
    opacity: 0.2;
  }
  30% {
    transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
    opacity: 1;
  }
  45% {
    transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  60% {
    transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  75% {
    transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  90% {
    transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  100% {
    transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
}

@-webkit-keyframes bigEntrance {
  0% {
    -webkit-transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
    opacity: 0.2;
  }
  30% {
    -webkit-transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
    opacity: 1;
  }
  45% {
    -webkit-transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  60% {
    -webkit-transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  75% {
    -webkit-transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  90% {
    -webkit-transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
}
.active.slideExpandUp {
  animation: slideExpandUp 1s ease-in;
}



@keyframes slideExpandUp {
  0% {
    transform: translateY(100%) scaleX(0.5);
  }
  30% {
    transform: translateY(-8%) scaleX(0.5);
  }
  40% {
    transform: translateY(2%) scaleX(0.5);
  }
  50% {
    transform: translateY(0%) scaleX(1.1);
  }
  60% {
    transform: translateY(0%) scaleX(0.9);
  }
  70% {
    transform: translateY(0%) scaleX(1.05);
  }
  80% {
    transform: translateY(0%) scaleX(0.95);
  }
  90% {
    transform: translateY(0%) scaleX(1.02);
  }
  100% {
    transform: translateY(0%) scaleX(1);
  }
}

@-webkit-keyframes slideExpandUp {
  0% {
    -webkit-transform: translateY(100%) scaleX(0.5);
  }
  30% {
    -webkit-transform: translateY(-8%) scaleX(0.5);
  }
  40% {
    -webkit-transform: translateY(2%) scaleX(0.5);
  }
  50% {
    -webkit-transform: translateY(0%) scaleX(1.1);
  }
  60% {
    -webkit-transform: translateY(0%) scaleX(0.9);
  }
  70% {
    -webkit-transform: translateY(0%) scaleX(1.05);
  }
  80% {
    -webkit-transform: translateY(0%) scaleX(0.95);
  }
  90% {
    -webkit-transform: translateY(0%) scaleX(1.02);
  }
  100% {
    -webkit-transform: translateY(0%) scaleX(1);
  }
}


#list2{
  display: none;
}
#list3{
  display: none;
}
#list4{
  display: none;
}
#list5{
  display: none;
}
#list6{
  display: none;
}
#list7{
  display: none;
}
#list8{
  display: none;
}
#list9{
  display: none;
}



.text-effect-wrapper,
.text {
  &::before,
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    pointer-events: none;
  }
}

.text-effect-wrapper {
  /* Can be anything light-colored */
  --spotlight-color: white;

  overflow: hidden;
  position: relative;
  /* Shimmer animation */
  &::before {
    animation: shimmer 5s infinite linear;
    background: radial-gradient(circle, var(--spotlight-color), transparent 25%)
        0 0 / 25% 25%,
      radial-gradient(circle, var(--spotlight-color), transparent 25%) 50% 50% / 12.5%
        12.5%;
    inset-block-start: -100%;
    inset-inline-start: -100%;
    mix-blend-mode: color-dodge;
    z-index: 3;
  }

  /* Extra filter to boost colors and contrast */
  &::after {
    backdrop-filter: blur(1px) brightness(90%) contrast(150%);
    z-index: 4;
  }
}

@keyframes shimmer {
  100% {
    transform: translate3d(50%, 50%, 0);
  }
}



.row > .column {
  padding: 0 8px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.column {
  float: left;
  width: 25%;
}

/* The gallerymodal (background) */
.gallerymodal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

/* gallerymodal Content */
.gallerymodal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  width: 70%;
  max-width: 1200px;
}



.mySlides {
  display: none;
}

.cursor {
  cursor: pointer;
}

/* Next & previous buttons */
.prev,
.next {
  background-color:black;
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

  /* On hover, add a black background color with a little bit see-through */
  .prev:hover,
  .next:hover {
      background-color: rgba(0, 0, 0, 0.8);
  }

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.closetext {
  background-color:black;
  color: #f2f2f2;
  font-size: 20px;
  padding: 8px 12px;
  position: absolute;
  right: 0px;
  top: 0;
}

  .closetext:hover,
  .closetext:focus {
      color: #999;
      text-decoration: none;
      cursor: pointer;
  }

img {
  margin-bottom: -4px;
}

.caption-container {
  text-align: center;
  padding: 2px 16px;
  color: white;
}

.demo {
  opacity: 0.6;
}

  .active,
  .demo:hover {
      opacity: 1;
  }

img.hover-shadow {
  padding: 10px;
  transition: 0.3s;
}

.hover-shadow:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}




/* 
ul.accordion-list {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  padding: 20px;
  margin: 0;
  list-style: none;
}
ul.accordion-list li {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  background-color: #FFF;
  padding: 20px;
  margin: 0 auto 15px auto;
  border: 1px solid #eee;
  border-radius: 15px;
  cursor: pointer;
}
ul.accordion-list li.active h3:after {
  transform: rotate(90deg);
}
ul.accordion-list li h3 {
  font-weight: 700;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  padding: 0 0 0 0;
  margin: 0;
  font-size: 15px;
  letter-spacing: 0.01em;
  cursor: pointer;
}
ul.accordion-list li h3:after {
  content: "►";
  font-family: "material-design-iconic-font";
  position: absolute;
  right: 0;
  top: 0;
  color: #006fb4;
  transition: all 0.3s ease-in-out;
  font-size: 18px;
}
ul.accordion-list li div.answer {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
ul.accordion-list li div.answer p {
  position: relative;
  display: block;
  font-weight: 300;
  padding: 10px 0 0 0;
  cursor: pointer;
  line-height: 150%;
  margin: 0 0 15px 0;
  font-size: 14px;
} */



.img-slider {
  position: relative;
  width: 100%;
}
.img-slider .slider-container {
  position: absolute;
  width: 300%;
  top: 0;
  left: 0;
  overflow: hidden;
  animation: slider 26s infinite;
}
.img-slider .slider-container .slide {
  position: relative;
  width: 33.33333333%;
  height: 100%;
  float: left;
  overflow: hidden
}
.img-slider .slider-container .slide img {
display: block;
width: 100%;
height: 100%
}
@keyframes slider {
  0%, 25%, 100%{left: 0}
  
  30%, 55%{left: -100%}
  
  60%, 85%{left: -200%}
}

@media only screen and (min-width: 450px) {
	.PopModal-content {
    width: 50%;
  }
  .img-slider {
    height: 480px;
  }
}

@media only screen and (max-width: 450px) {
  .PopModal-content {
    width: 90%;
  }
  .img-slider {
    height: 100px;
  }
}


.gallery-wrapper {
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  padding: 0 1em;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-gap: 1em;
}
.gallery-wrapper .image-wrapper a {
  padding: 0.5em;
  display: block;
  width: 100%;
  text-decoration: none;
  color: #333;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  transition: all 200ms ease-in-out;
  text-align: center;
}
.image-title{text-align: center;}
.gallery-wrapper .image-wrapper a:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}
.gallery-wrapper .image-wrapper a img {
  width: 100%;
}
.gallery-lightboxes .image-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0ms ease-in-out;
}
.gallery-lightboxes .image-lightbox:target {
  opacity: 1;
  visibility: visible;
}
.gallery-lightboxes .image-lightbox:target .image-lightbox-wrapper {
  opacity: 1;
  margin-top: 80px;
  transform: scale(1, 1) translateY(0);
}
.gallery-lightboxes .image-lightbox .image-lightbox-wrapper {
  transform: scale(0.95, 0.95) translateY(-30px);
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
  opacity: 0;
  margin: 1em auto;
  max-width: 75%;
  padding: 0.5em;
  display: inline-block;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
  position: relative;
}
.gallery-lightboxes .image-lightbox .image-lightbox-wrapper .close {
  width: 1.5em;
  height: 1.5em;
  background: #000;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  border-radius: 50%;
  box-shadow: 0 0 0 2px white inset, 0 0 5px rgba(0, 0, 0, 0.5);
  position: absolute;
  right: -1em;
  top: -1em;
}
.gallery-lightboxes .image-lightbox .image-lightbox-wrapper .close:before {
  content: "";
  display: block;
  width: 10px;
  height: 2px;
  background: #fff;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -1px 0 0 -5px;
  transform: rotate(-45deg);
}
.gallery-lightboxes .image-lightbox .image-lightbox-wrapper .close:after {
  content: "";
  display: block;
  width: 10px;
  height: 2px;
  background: #fff;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -1px 0 0 -5px;
  transform: rotate(45deg);
}
.gallery-lightboxes .image-lightbox .image-lightbox-wrapper .arrow-left {
  position: absolute;
  top: 0;
  right: 50%;
  bottom: 0;
  left: 0;
}
.gallery-lightboxes .image-lightbox .image-lightbox-wrapper .arrow-left:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-bottom: 0;
  border-right: 0;
  border-radius: 4px 0 0 0;
  position: absolute;
  top: 50%;
  right: 100%;
  cursor: pointer;
  transform: rotate(-45deg) translateY(-50%);
}
.gallery-lightboxes .image-lightbox .image-lightbox-wrapper .arrow-right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
}
.gallery-lightboxes .image-lightbox .image-lightbox-wrapper .arrow-right:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-bottom: 0;
  border-left: 0;
  border-radius: 0 4px 0 0;
  position: absolute;
  top: 50%;
  left: 100%;
  cursor: pointer;
  transform: rotate(45deg) translateY(-50%);
}
.gallery-lightboxes .image-lightbox .image-lightbox-wrapper img {
  margin: 0 auto;
  max-height: 70vh;
}
